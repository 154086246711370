var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseDialog',{attrs:{"height":_vm.showSearch ? '50vh' : 'auto',"dialogVisible":_vm.dialog,"title":_vm.title},on:{"update:dialogVisible":function($event){_vm.dialog=$event},"update:dialog-visible":function($event){_vm.dialog=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('LoadingBtn',{on:{"click":_vm.cancel}},[_vm._v(" 取消 ")]),_c('el-button',{attrs:{"loading":_vm.loading,"type":"primary","size":"small"},on:{"click":_vm.doSubmit}},[_vm._v(" "+_vm._s(_vm.title)+" ")])]},proxy:true}])},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"offShelvesFormComponent"},[(_vm.showSearch)?_c('div',{staticClass:"off-header"},[_c('el-checkbox',{model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}},[_vm._v("全选")]),_c('el-input',{attrs:{"clearable":"","placeholder":"搜索原型名称","suffix-icon":"el-icon-search"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1):_vm._e(),_c('div',{staticClass:"table-wrapper"},[_c('CommonTable',{attrs:{"height":"auto","selection":false,"cols":_vm.cols,"infoData":_vm.domData},scopedSlots:_vm._u([{key:"nameSlot",fn:function(ref){
var scoped = ref.scoped;
var index = ref.scoped.index;
return [_c('el-checkbox',{on:{"change":function($event){return _vm.proStatusChangeHandler($event, index)}},model:{value:(_vm.data[index].status),callback:function ($$v) {_vm.$set(_vm.data[index], "status", $$v)},expression:"data[index].status"}},[_vm._v(" "+_vm._s(scoped[scoped.prop])+" ")])]}},{key:"prim_structSlot",fn:function(ref){
var scoped = ref.scoped;
var index = ref.scoped.index;
return [_vm._l((scoped[scoped.prop] || []),function(item){return [_c('el-checkbox',{key:item.id,on:{"change":function($event){return _vm.structChangeHandler($event, index)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}},[_vm._v(_vm._s(item.name))])]})]}},{key:"sizesSlot",fn:function(ref){
var scoped = ref.scoped;
var index = ref.scoped.index;
return [_vm._l((scoped[scoped.prop] || []),function(item){return [_c('el-checkbox',{key:item.id,on:{"change":function($event){return _vm.sizeChangeHandler($event, index)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}},[_vm._v(_vm._s(item.sizeName))])]})]}},{key:"messageSlot",fn:function(ref){
var scoped = ref.scoped;
return [_c('color-text-btn',{attrs:{"type":scoped[scoped.prop].status ? 'success' : 'danger'}},[_vm._v(" "+_vm._s(scoped[scoped.prop].text)+" ")])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }